<template>
    <div class="muz-clock-digital">{{time}}</div>
</template>

<script>
import XDate from "@/utils/x-date.js"
export default {
    props: {
        format: {
            type: String,
            default: "yyyy-mm-dd HH:ii:ss.fff"
        },
        interval: {
            type: [Number, Boolean],
            default: false
        },
    },
    data: () => ({
        timer: null,
        time: '',
    }),
    created() {
        this.init();
    },
    mounted() {
    },
    methods: {
        init(){
            if(this.interval && !this.timer){
                this.timer = setInterval(()=>{
                    this.updata();
                }, this.interval)
            }
            else{
                this.updata();
            }
        },
        updata(){
            const nowTime = XDate.format(this.format);
            this.time = nowTime;
        },
    },
}
</script>

<style>
@import url("./fonts/UnidreamLED.css");
.muz-clock-digital{font-family: "UnidreamLED";}
</style>
