<template>
    <div class="com-time">
        <div class="digital-time"><muz-clock-digital :interval="7" format="HH:ii:ss.f"/></div>
        <div class="right">
            <!-- <div class="digital-weather">晴 7℃ 西风 2级</div> -->
            <div class="digital-weather">{{tableWeathers.join(' ')}}</div>
            <div class="digital-date">
                <muz-clock-digital format="yyyy年mm月dd日 周w"/>
            </div>
        </div>
    </div>
</template>

<script>
import MuzClockDigital from '@/components/muz-clock-digital/muz-clock-digital.vue';
import ajax from "@/ajax.js";
export default {
    components: {
        MuzClockDigital,
    },
    data: () => ({
        getWeather : {},
        tableWeathers :[],
    }),
    created() {
    },
    mounted() {
        this.loadWeather();
    },
    methods:{
        loadWeather() {
            ajax({
                url: 'https://devapi.qweather.com/v7/weather/3d?location=101300101&key=2b2a47fba7d24ed2a70e0c06444f6755',
            }).then(res => {
                this.getWeather = res,
                // console.log("loadWeather: res:", this.getWeather);
                this.update();
            }).catch(err =>{
                // console.log("错误：",err);
            })
        },
        update(){
            const today = this.getWeather.daily.map(it => {
                return {
                    textDay: it.textDay,
                    text: it.tempMin+"/"+it.tempMax+"℃",
                    windSpeedDay: it.windSpeedDay+"级",
                    windDirDay: it.windDirDay,
                }
            })
            if(!today.length)
                return;
            this.tableWeathers = [
                today[0].textDay,
                today[0].text,
                today[0].windDirDay,
                today[0].windSpeedDay,
            ];
        }
    }
}
</script>

<style>
.com-time{display: flex; padding: 0 5px; height: 60px; align-items: center; justify-content: space-between; background: rgba(0, 0, 0, 0.2); border: 1px solid rgba(64, 224, 144, 1); border-radius: 10px; }

.com-time .digital-time{color: rgba(165,240,240,1); font-size: 40px;}
.com-time .digital-weather{color: rgba(233,85,19,1); font-size: 14px; white-space: nowrap; }
.com-time .digital-date{color: rgba(165,240,240,1); }
.com-time .digital-date .muz-clock-digital{font: 14px Arial,sans-serif; white-space: nowrap;}

@media(max-width: 1200px){
    .com-time{ height: 90px; flex-direction: column; justify-content: center; }
}
/* .com-time .weatherList .digital-weather {display: flex;} */
</style>
